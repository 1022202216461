<template>
    <div class="container_n">
        <!-- 头部 -->
        <headerTab :headerConfig="headerConfig"></headerTab>
        <!--内容-->
        <div class="content_n">
            <div class="box_n" v-for="(item, index) in nextList" :key="index">
                <div v-if="detailsDate.close_state == 0" style="display:flex">
                    <div class="left_n">
                        <div>
                            <div class="bar_n" v-if="detailsDate.audit_state >= index">
                                <div class="bar_n_m"></div>
                            </div>
                            <div v-else-if="detailsDate.audit_state < index" class="bar_n_r"></div>
                        </div>
                        <div v-if="index != 4">
                            <div class="line_n_y" v-if="detailsDate.audit_state >= index">
                                <div class="line_n_s"></div>
                                <div class="triangle"></div>
                            </div>
                            <!-- 未选中 -->
                            <div class="line_n_n" v-else-if="detailsDate.audit_state < index">
                                <div class="line_n_s"></div>
                                <div class="triangle"></div>
                            </div>
                        </div>

                    </div>
                    <!-- 备注字段 -->
                    <div class="right_n">
                        <div class="right_n_title">{{ item.title }}</div>
                        <div v-if="detailsDate.audit_state >= index">
                            <div v-if="index == 0" class="right_n_details" @click="detailsBtn(detailsDate.audit_id)">查看详情
                                ></div>
                            <div v-else>
                                <div v-if="item.show == 1" class="right_n_details">{{ item.successText }}</div>
                                <div v-if="item.show == 2" class="right_n_details">{{ item.infoText }}</div>
                                <div v-if="item.show == 3" class="right_n_details" style="color:red">{{ item.errorText }}
                                </div>
                                <div v-if="item.show == 3">{{ item.close_remark }}</div>
                                <div v-if="index == 2 && item.show == 2" class="right_n_details" style="display:flex">
                                    <div style="color:rgba(26, 26, 26, 1);margin-right:10px">
                                        运费：{{ configData.shares_trade_shipping_fee }}</div>
                                    <div v-if="detailsDate.pay_state == 3" style="display:flex">
                                        <div class="message_n_s" style="color:red;margin-right:10px">
                                            备注：{{ detailsDate.pay_remark.remark }}</div>
                                        <div @click="uploadBtn">重新上传 ></div>
                                    </div>
                                    <div v-else @click="uploadBtn">去支付 ></div>
                                </div>
                                <div class="right_n_details" style="margin-top:5px" v-if="index == 2 && item.show == 1"
                                    @click="lookIcon(detailsDate.pay_info[0].img)">查看凭证 ></div>
                                <div class="right_n_details" style="margin-top:5px" v-if="index == 4 && item.show == 1"
                                    @click="location(item)">查看物流 ></div>
                            </div>
                            <div style="margin-top:20px">{{ item.timeText }}</div>
                        </div>
                    </div>
                </div>
                <!--未关闭 -->
                <div v-else style="display:flex">
                    <div class="left_n">
                        <div>
                            <div class="bar_n" v-if="item.step < (nextStep + 1)">
                                <div class="bar_n_m"></div>
                            </div>
                            <div v-else-if="item.step < (nextStep + 1)" class="bar_n_r"></div>
                        </div>
                        <div v-if="index != 4 || detailsDate.close_state != 0">
                            <div class="line_n_y" v-if="item.step < nextStep + 1">
                                <div class="line_n_s"></div>
                                <div class="triangle"></div>
                            </div>
                            <!-- 未选中 -->
                            <div class="line_n_n" v-else-if="item.step > (nextStep + 1) && detailsDate.close_state == 0">
                                <div class="line_n_s"></div>
                                <div class="triangle"></div>
                            </div>
                        </div>

                    </div>
                    <!-- 备注字段 -->
                    <div class="right_n">
                        <div class="right_n_title" v-if="item.step < (nextStep + 1)">{{ item.title }}</div>
                        <div v-if="detailsDate.audit_state > index + 1">
                            <div v-if="index == 0" class="right_n_details" @click="detailsBtn(detailsDate.audit_id)">查看详情
                                ></div>
                            <div v-else>
                                <div v-if="item.show == 1" class="right_n_details">{{ item.successText }}</div>
                                <div v-if="item.show == 2" class="right_n_details">{{ item.infoText }}</div>
                                <div v-if="item.show == 3" class="right_n_details" style="color:red">{{ item.errorText }}
                                </div>
                                <div v-if="item.show == 3">{{ item.close_remark }}</div>
                                <div v-if="index == 2 && item.show == 2" class="right_n_details" style="display:flex">
                                    <div style="color:rgba(26, 26, 26, 1);margin-right:10px">
                                        运费：{{ configData.shares_trade_shipping_fee }}</div>
                                    <div v-if="detailsDate.pay_state == 3" style="display:flex">
                                        <div class="message_n_s" style="color:red">备注：{{ detailsDate.pay_remark.remark }}
                                        </div>
                                        <div @click="uploadBtn">重新上传 ></div>
                                    </div>
                                    <div v-else @click="uploadBtn">去支付{{ detailsDate.pay_state == 3 }} ></div>
                                </div>
                                <div class="right_n_details" style="margin-top:5px" v-if="index == 2 && item.show == 1"
                                    @click="lookIcon(detailsDate.pay_info[0].img)">查看凭证 ></div>
                                <div class="right_n_details" style="margin-top:5px" v-if="index == 4 && item.show == 1"
                                    @click="location(item)">查看物流 ></div>
                            </div>
                            <div>{{ item.timeText }}</div>
                        </div>
                        <div v-else-if="detailsDate.close_state != 0 && detailsDate.audit_state == index + 1">
                            <div v-if="detailsDate.close_state == 1" style="color:red"
                                class="right_n_details message_n_s">原因：{{ detailsDate.close_remark.remark }}</div>
                            <div v-else class="right_n_details message_n_s" style="color:red">
                                系统关闭：{{ detailsDate.close_remark.remark }}</div>
                            <div style="margin-top:20px;color:red">{{ detailsDate.close_at }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 已关闭 -->
            <!-- 已结束 -->
            <div class="box_n" v-if="detailsDate.close_state != 0">
                <div class="left_n">
                    <div>
                        <div class="bar_n">
                            <div class="bar_n_m"></div>
                        </div>
                    </div>
                </div>
                <div class="right_n">
                    <div class="right_n_title">已结束</div>
                </div>
            </div>
            <div class="clone_n" @click="cloneBtn" v-if="detailsDate.audit_state == 1 && detailsDate.close_state == 0">关闭申请
            </div>
        </div>
    </div>
</template>
<script>
import headerTab from "@/components/headerTab";
import {
    sharesChangeDetails_api,
    sharesChangePay_api,
    cloneSharesChange_api
} from "@/api/alteration";
import * as apiCommon from "@/api/common";
import wx from "weixin-js-sdk";
import { Toast } from "vant";
import { Dialog } from "vant";
import { getConfig_api } from "@/api/deal";
import { ImagePreview } from "vant";
export default {
    data() {
        return {
            //
            headerConfig: {
                title: "处理进度"
            },
            // 流程步骤
            nextStep: "",
            configData: {},
            uploadData: {
                getToken: "",
                imgConfig: "{ useCdnDomain: true }",
                num: 1
            },
            nextList: [
                {
                    title: "信息提交",
                    state: 2,
                    step: 1,
                    show: 2,
                    successText: "查看详情 >",
                    errorText: "",
                    timeText: ""
                },
                {
                    title: "物料审核",
                    state: 1,
                    step: 2,
                    show: 2,
                    infoText: "等待平台收件确认物料",
                    successText: "物料信息确认",
                    errorText: "用户关闭",
                    timeText: ""
                },
                {
                    title: "支付运费",
                    state: 1,
                    step: 3,
                    show: 2,
                    infoText: "",
                    successText: "已支付",
                    errorText: "",
                    timeText: ""
                },

                {
                    title: "物料制作",
                    state: 1,
                    step: 4,
                    show: 2,
                    infoText: "等待物料制作",
                    successText: "制作完成",
                    errorText: "合同生成失败",
                    timeText: ""
                },
                {
                    title: "合同回寄",
                    state: 1,
                    step: 5,
                    show: 2,
                    infoText: "等待物料发货",
                    successText: "",
                    errorText: "",
                    timeText: ""
                }
            ],
            detailsDate: {},
            id: "",
            fileList: [],
            // 节流阀
            payStatusShow: true,
        };
    },
    components: {
        headerTab
    },
    created() {
        document.title = "处理进度";
        console.log(this.$route.query);
        this.id = this.$route.query.id;
        this.getDetails(this.$route.query.id);
        // 获取运费
        this.getSeting();
        this.getToken();
    },
    methods: {
        location(row) {
            console.log(row);
            if (this.detailsDate.shipments.length != 2) {
                Toast({
                    message: "暂无物流信息",
                    icon: "success",
                    duration: 1000
                });
            } else {
                this.$router.push(
                    `../materialCenter/shipmentsList?data=${encodeURIComponent(
                        JSON.stringify(this.detailsDate.shipments[1].shipments_info)
                    )}`
                );
            }
        },
        // 关闭
        cloneBtn() {
            Dialog.confirm({
                title: "确认",
                message: "确认关闭申请"
            })
                .then(() => {
                    cloneSharesChange_api(this.detailsDate.audit_id).then(res => {
                        if (res.code == 0) {
                            Toast({
                                message: "操作成功",
                                icon: "success",
                                duration: 1000
                            });
                            this.getDetails(this.$route.query.id);
                        } else {
                            Toast({
                                message: res.error,
                                icon: "error",
                                duration: 1000
                            });
                        }
                    });
                })
                .catch(() => {
                    // on cancel
                });
        },
        // 查看凭证
        lookIcon(img) {
            console.log(img);
            ImagePreview([img]);
        },
        //
        getSeting() {
            let data = {
                config: ["shares_trade_shipping_fee"]
            };
            getConfig_api(data).then(res => {
                this.configData = res.data;
            });
        },
        // 查看详情
        detailsBtn(id) {
            this.$router.push(`./logistics?id=${id}`);
        },
        getDetails(id) {
            sharesChangeDetails_api(id).then(res => {
                if (res.code == 0) {
                    console.log(res);
                    this.nextList[0].timeText = res.data.create_time;
                    this.nextList[1].timeText = res.data.audit_info_at;
                    this.nextList[2].timeText = res.data.pay_at;
                    this.nextList[3].timeText = res.data.pay_admin_at;
                    this.nextList[4].timeText = res.data.audit_at;
                    this.nextStep = res.data.audit_state;

                    this.nextList.forEach((item, index) => {
                        if (index < res.data.audit_state) {
                            item.show = 1;
                        } else if (index == res.data.audit_state) {
                            item.show = 2;
                        } else {
                            item.show = 4;
                        }
                    });
                    if (res.data.close_state != 0) {
                        if (res.data.audit_state == 5) {
                            this.nextList[res.data.audit_state - 2].show = 3;
                            this.nextList[res.data.audit_state - 2].timeText = res.data.close_at;
                        } else {
                            this.nextList[res.data.audit_state].show = 3;
                            this.nextList[res.data.audit_state].timeText = res.data.close_at;
                        }

                    }
                    console.log(this.nextStep);
                    this.detailsDate = res.data;
                }
            });
        },
        // 支付运费
        async getToken() {
            console.log(2);
            let res = await apiCommon.getImgToken();
            if (!res || typeof res === "string" || res.error)
                return this.$toast.fail(res ? res.error || res : "获取七牛云信息失败!");
            this.uploadData.imgToken = res.data;
        },
        async uploadBtn() {
            if (!this.payStatusShow) {
                return false
            }
            this.payStatusShow = false
            let url = location.href.split("#")[0];
            let res1 = await apiCommon.getConfig({ url: url });
            let tableData = res1.data;
            tableData.url = encodeURIComponent(tableData.url);

            tableData.jsApiList.push("getLocalImgData");
            tableData.jsApiList.push("uploadFile");
            wx.config({
                debug: false, //生产环境需要关闭debug模式
                appId: tableData.appId, //appId通过微信服务号后台查看
                timestamp: tableData.timestamp, //生成签名的时间戳
                nonceStr: tableData.nonceStr, //生成签名的随机字符串
                signature: tableData.signature, //签名
                jsApiList: tableData.jsApiList,
                url: tableData.url
            });
            let that = this;
            if (res1.code == 0) {
                wx.chooseImage({
                    succress: function (res) {
                        console.log(res);
                    },
                    fail: function (res) {
                        that.loadingshow = false;
                        that.payStatusShow = true
                        // console.log("fail");
                        console.log(res);
                    },

                    complete: function (res) {
                        if (res.errMsg == "chooseImage:ok") {
                            that.loadingshow = true;
                            that.imgData(res);
                        } else if (res.errMsg == "chooseImage:cancel") {
                            that.loadingshow = false;
                            that.payStatusShow = true
                        } else {
                            // Toast("获取图片失败，请重新选择");
                            console.log(1);
                        }
                    }
                });
            } else {
                this.payStatusShow = true
            }
        },
        async imgData(res) {
            for (var i = 0; i < res.localIds.length; i++) {
                console.log(i);
                var localId = res.localIds[i];
                await this.mostImg(localId);
            }
        },
        mostImg(item) {
            let that = this;
            return new Promise(resolve => {
                wx.getLocalImgData({
                    localId: item,
                    succress: function (res) {
                        console.log(res);
                    },
                    complete: function (res) {
                        let localData = res.localData;
                        that.upload(localData);
                        resolve("done!");
                    },
                    fail: function () {
                        that.$toast.clear();
                        this.payStatusShow = true
                        that.loadingshow = false;
                    }
                });
            });
        },
        fildsData(dataurl, filename) {
            //将base64转换为文件
            var arr = dataurl.split(",");
            var mime = arr[0].match(/:(.*?);/)[1];
            var bstr = atob(arr[1]);
            var n = bstr.length;
            var u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            this.upload(
                new File([u8arr], filename, {
                    type: mime
                })
            );
        },
        fileSize(str) {
            var fileSize;
            if (str.indexOf("=") > 0) {
                var indexOf = str.indexOf("=");
                str = str.substring(0, indexOf);
            }
            fileSize = parseInt(str.length - (str.length / 8) * 2);
            return fileSize;
        },
        async upload(img) {
            let that = this;
            if (img.indexOf("base64") > -1) {
                img = img.split(",")[1];
            }
            let picUrl = "";
            var url = "https://up-z2.qiniup.com/putb64/" + that.fileSize(img);
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function (res) {
                if (res.currentTarget.readyState == 4) {
                    picUrl =
                        "https://cdn.health.healthplatform.xyz/" +
                        JSON.parse(xhr.responseText).key;
                    that.fileList.push({ url: picUrl });
                    if (that.fileList.length != 0) {
                        console.log(12312312);
                        // ;
                    }
                    that.payStatus(that.fileList[0].url);
                } else {
                    this.payStatusShow = true
                }
            };
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-Type", "application/octet-stream");
            xhr.setRequestHeader(
                "Authorization",
                "UpToken " + that.uploadData.imgToken
            );
            xhr.send(img);
            this.loadingshow = false;
            console.log(that.fileList.length);
            if (that.fileList.length != 0) {
                console.log(12312312);
                // ;
            }
            // that.payStatus(that.fileList);
        },
        payStatus(fileListUrl) {
            let data = {
                pay_info: {
                    img: fileListUrl
                }
            };
            sharesChangePay_api(data, this.id).then(res => {
                if (res.code == 0) {
                    Toast({
                        message: "操作成功",
                        icon: "success",
                        duration: 1000
                    });
                    this.getDetails(this.id);
                }
            });
        }
    }
};
</script>
<style>
.container_n {
    background-color: #f9f9f9;
    width: 100%;
    height: 100%;
}

.content_n {
    width: 700px;
    margin: 30px auto 0;
    background-color: #fff;
    padding: 80px 65px;
    box-sizing: border-box;
}

.message_n_s {
    width: 200px
}

.bar_n {
    width: 30px;
    height: 30px;
    background: rgba(255, 68, 68, 0.2);
    border-radius: 30px;
    opacity: 1;
    padding-top: 5px;
    box-sizing: border-box;
}

.bar_n_m {
    width: 20px;
    height: 20px;
    background: #ff4444;
    border-radius: 20px;
    opacity: 1;
    margin: auto auto;
}

.line_n_y {
    margin-bottom: 20px;
}

.line_n_y .line_n_s {
    width: 2.5px;
    height: 150px;
    background-color: #ff4444;
    margin: 20px 0 0 14px;
}

.line_n_y .triangle {
    width: 0;
    height: 0;
    border-top: 15px solid #ff4444;
    border-right: 10px solid transparent;
    border-left: 9px solid transparent;
    margin-left: 5px;
}

.line_n_n .line_n_s {
    width: 2.5px;
    height: 150px;
    background: rgba(26, 26, 26, 0.2);
    margin: 20px 0 0 14px;
}

.line_n_n .triangle {
    width: 0;
    height: 0;
    border-top: 15px solid rgba(26, 26, 26, 0.2);
    border-right: 10px solid transparent;
    border-left: 9px solid transparent;
    margin-left: 5px;
}

.line_n_n {
    margin-bottom: 20px;
}

.bar_n_r {
    width: 20px;
    height: 20px;
    background: rgba(26, 26, 26, 0.2);
    border-radius: 20px;
    opacity: 1;
    margin-left: 5px;
}

.right_n_title {
    font-weight: 500;
    color: #000;
    margin-top: 5px;
}

.box_n {
    display: flex;
}

.right_n {
    margin-left: 20px;
    font-size: 26px;
}

.right_n>div {
    margin-bottom: 20px;
}

.right_n_details {
    color: #0038ff;
}

.clone_n {
    width: 300px;
    height: 80px;
    background: #f1f1f1;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    text-align: center;
    line-height: 80px;
    font-size: 32px;
    color: #ff4444;
    margin: 200px auto 0;
}

.van-toast {
    width: 300px;
    min-height: 120px;
    line-height: 40px;
}
</style>